<template>
  <div class="examination-page">
    <!--    upcoming-exam-section-->
    <div class="upcoming-exam-section mb-lg-5">
      <div class="title">
        Upcoming Exam
      </div>
      <div class="row row-cols-lg-3 row-cols-md-2">
        <div class="col" v-for="upcoming_exam in upcomingExams" style="margin-bottom: 30px;">
          <div class="exam-items">
            <h5>{{ upcoming_exam.assessment.type }} exam</h5>
            <h3>{{ upcoming_exam.assessment.title }}</h3>
            <p>
              <span>To Start {{ upcoming_exam.assessment.type }} exam please click on Start Now button</span></p>
            <div class="d-flex">
              <router-link :to="{name:'startExam', params:{uuid: upcoming_exam.unique_id }}" class="btn-animate btn">
                Start now <img src="/assets/arrow-right2.svg" alt="">
              </router-link>
            </div>
          </div>
        </div>
        <div v-if="upcomingExams.length == 0">
          <p>There is no up coming mcq exam for you</p>
        </div>
      </div>
    </div>
    <!--    exam result-->
    <div class="exam-result">
      <div class="title">
        Exam Result
      </div>
      <div class="row row-cols-lg-3 row-cols-md-2">
        <div class="col" v-for="exam_result in examResults" style="margin-bottom: 25px;">
          <div class="exam-items">
            <h5>{{ exam_result.assessment.type }} exam</h5>
            <h3>{{ exam_result.assessment.title }}</h3>
<!--            <p>start at <b>{{ exam_result.exam_started_at | clock }}</b> <span>{{ exam_result.time }}</span>
              ({{ exam_result.leftDays }}
              days left)</p>-->

            <div class="result text-capitalize">
              Result :
              <span :class="checkStatus(exam_result.status)">
                <span v-if="exam_result.score > 0">{{ exam_result.score }} out of {{ exam_result.assessment.score }}</span>
                <span v-else-if="exam_result.score <= 0" class="text-warning">Under Review</span>
            </span>
            </div>
            <div class="d-flex"
                 v-if="exam_result.step && exam_result.step.current_step != exam_result.step.question_ids.length">
              <router-link :to="{name:'startExam', params:{uuid: exam_result.unique_id }}" class="btn-animate btn">
                Resume Exam <img src="/assets/arrow-right2.svg" alt="">
              </router-link>
            </div>
          </div>
        </div>

        <div class="col" v-if="examResults.length == 0">
          <p>There is no exam result to shown</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
import moment from "moment";

export default {
  name: "Examination",

  data: () => ({
    upcomingExams: [],
    examResults: [],
  }),

  methods: {
    checkStatus(status) {
      let data = "";
      switch (status) {
        case "pending":
          data = "status-pending";
          break;
        case "pass":
          data = "status-reviewed";
          break;
        case "fail":
          data = "status-rejected";
          break;
      }
      return data;
    }
  },
  filters: {
    clock: (value) => {
      return moment(value).format('hh:mm:ss')
    }
  },
  mounted() {
    axios.get('/assessment/').then(response => {
      response.data.forEach(value => {
        if (value.exam_started_at == null) {
          this.upcomingExams.push(value)
        } else {
          this.examResults.push(value)
        }
      })
      this.upcomingExams.sort( ( a, b ) => {
        const orderTitle = ['OOP Test ( Senior )', 'Laravel Developer - MCQ', 'Laravel Developer - Coding', 'Laravel Developer - Viva'];

        const aAssessmentTitle = orderTitle.indexOf(a.assessment.title);
        const bAssessmentTitle = orderTitle.indexOf(b.assessment.title);

        if ( aAssessmentTitle === bAssessmentTitle )
          return a - b;

        return aAssessmentTitle - bAssessmentTitle;
      } );



    }).catch(error => {
      console.error(error.response)
    })
  }
}
</script>

<style scoped>

</style>